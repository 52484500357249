<template>
  <div>
    <TitleWithBackButton title="" back-route="Settings" icon="mdi-graphql"></TitleWithBackButton>
    <v-container>
      <div v-if="!loading">
        <v-card
          class=""
          flat
          color="transparent"
        >
          <v-card-title class="pb-0">{{$translations.t('settings.colorizeServiceSelector.title')}}</v-card-title>
          <v-card-text class="text-left">
            <div class="pb-2">{{$translations.t('settings.colorizeServiceSelector.description')}}</div>
            <BooleanSetting
              :value="colorizeServiceSelector"
              settingKey="colorizeServiceSelector"
              @set="setSetting"
            ></BooleanSetting>
          </v-card-text>
        </v-card>
        <v-card
          class=""
          flat
          color="transparent"
        >
          <v-card-title class="pb-0">{{$translations.t('settings.colorizeOperatorSelector.title')}}</v-card-title>
          <v-card-text class="text-left">
            <div class="pb-2">{{$translations.t('settings.colorizeOperatorSelector.description')}}</div>
            <BooleanSetting
              :value="colorizeOperatorSelector"
              settingKey="colorizeOperatorSelector"
              @set="setSetting"
            ></BooleanSetting>
          </v-card-text>
        </v-card>
        <v-card
          class=""
          flat
          color="transparent"
        >
          <v-card-title class="pb-0">{{$translations.t('settings.weeklyReminder.title')}}</v-card-title>
          <v-card-text class="text-left">
            <div class="pb-2">{{$translations.t('settings.weeklyReminder.description')}}</div>
            <BooleanSetting
              :value="weeklyReminder"
              settingKey="weeklyReminder"
              @set="setSetting"
            ></BooleanSetting>
          </v-card-text>
        </v-card>
        <v-card
          class=""
          flat
          color="transparent"
        >
          <v-card-title class="pb-0">{{$translations.t('settings.dailyReminder.title')}}</v-card-title>
          <v-card-text class="text-left">
            <div class="pb-2">{{$translations.t('settings.dailyReminder.description')}}</div>
            <BooleanSetting
              :value="dailyReminder"
              settingKey="dailyReminder"
              @set="setSetting"
            ></BooleanSetting>
          </v-card-text>
        </v-card>
      </div>
      <div v-else class="d-flex justify-center align-center">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </div>
    </v-container>
  </div>
</template>

<script>
import StandardColorPicker from '@/components/common/StandardColorPicker.vue'
import settingService from '@/services/settings/settings.service.js'
import Vue from 'vue'
import * as components from "./generalSettingComponents"
import TitleWithBackButton from '@/components/common/TitleWithBackButton.vue';

export default {
  name: "Theme",
  components: { 
    StandardColorPicker,
    TitleWithBackButton,
    ...components
  },
  data: function() {
    return {
      colorizeServiceSelector: undefined,
      colorizeOperatorSelector: undefined,
      colorizeAppSelector: undefined,
      weeklyReminder: undefined,
      dailyReminder: undefined,
      dark: undefined,
      loading: false,
      settings: []
    }
  },
  props: {
    bus: {
      type: Object,
      default: function() {
        return new Vue()
      }
    }
  },
  mounted: function() {
    this.loadSettings()
  },
  methods: {
    loadSettings: async function() {
      this.loading = false
      settingService.list('theme').then((list) => {
        let settings = []
        for(const [key, value] of Object.entries(list)) {
          settings.push({
            key: key,
            value: value.value
          })
        }
        settings = settings.filter(a=> a.key != 'colorizeAppSelector')
        this.settings = settings
      })
    },
    setSetting: function(key, value) {
      this.$settings.set(key, value).then(() => {
        this.loadSettings()
      })
    }
  },
  computed: {
  },
  settings: {
    colorizeServiceSelector: {
      bind: 'colorizeServiceSelector'
    },
    colorizeOperatorSelector: {
      bind: 'colorizeOperatorSelector'
    },
    colorizeAppSelector: {
      bind: 'colorizeAppSelector'
    },
    dark: {
      bind: 'dark'
    },
    weeklyReminder: {
      bind: 'weeklyReminder'
    },
    dailyReminder: {
      bind: 'dailyReminder'
    }
  },
  watch: {
    colorizeServiceSelector(newValue) {
      this.loadSettings()
    }
  }
}
</script>

<style>

</style>